<template>
  <div>
    <v-layout wrap justify-center pb-8 class="mainfont mainbg2">
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#29807c"
        spinner="spinner"
      />
      <v-flex xs12 class="mainbg2">
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex xs12 pa-3 text-center>
                <span
                  class="mainfont"
                  style="color: black; font-size: 18px; font-weight: bold"
                >
                  Live Auction
                </span>
              </v-flex>
              <v-flex xs12 lg11 v-if="liveAuctions.length > 0">
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-carousel
                      cycle
                      :interval="2000"
                      height="140"
                      hide-delimiters
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(item, i) in liveAuctions"
                        :key="i"
                      >
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <v-card
                              @click="$router.push('/LiveAuctionsPage')"
                              class="bg8 pa-3"
                            >
                              <v-layout wrap justify-center>
                                <v-flex
                                  xs12
                                  lg2
                                  pa-3
                                  v-for="(item2, k) in item.photos"
                                  :key="k"
                                >
                                  <v-avatar v-if="item2" size="90px">
                                    <v-img :src="mediaURL + item2"></v-img>
                                  </v-avatar>
                                </v-flex>
                                <v-flex xs3 align-self-center>
                                  <v-layout wrap justify-center fill-height>
                                    <v-flex align-self-center>
                                      <v-layout wrap justify-center>
                                        <v-flex xs12 align-self-center>
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 18px;
                                              color: white;
                                            "
                                          >
                                            Lot No :
                                          </span>
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 22px;
                                              color: white;
                                            "
                                          >
                                            {{ item.lotNumber }}
                                          </span>
                                        </v-flex>
                                        <v-flex xs12>
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 22px;
                                              color: white;
                                            "
                                          >
                                            {{ item.netWeight }}
                                          </span>
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 18px;
                                              color: white;
                                            "
                                          >
                                            Kg
                                          </span>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs3 align-self-center>
                                  <v-layout wrap justify-center fill-height>
                                    <v-flex align-self-center>
                                      <v-layout wrap justify-center>
                                        <v-flex xs9 pr-2>
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 18px;
                                              color: white;
                                            "
                                          >
                                            Ending Time
                                          </span>
                                        </v-flex>
                                        <v-flex xs12 align-self-center>
                                          <v-btn width="70%" rounded>
                                            <v-icon color="black"
                                              >mdi-clock</v-icon
                                            >

                                            &nbsp;&nbsp;
                                            <span
                                              class="mainfont"
                                              style="
                                                font-size: 18px;
                                                color: #000000;
                                              "
                                            >
                                              {{ formatTime(item.endingTime) }}
                                            </span>
                                          </v-btn>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs3 align-self-center>
                                  <v-layout wrap justify-center fill-height>
                                    <v-flex align-self-center>
                                      <v-layout wrap justify-center>
                                        <v-flex
                                          v-if="item.bidPrice === 0"
                                          text-center
                                          xs12
                                          align-self-center
                                        >
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 18px;
                                              color: white;
                                            "
                                          >
                                            Rs
                                          </span>
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 24px;
                                              color: white;
                                            "
                                          >
                                            {{ item.startingPrice }}
                                          </span>
                                          <v-icon color="white"
                                            >mdi-arrow-up-right-bold</v-icon
                                          >
                                        </v-flex>
                                        <v-flex
                                          v-else
                                          text-center
                                          xs12
                                          align-self-center
                                          ><span
                                            class="mainfont"
                                            style="
                                              font-size: 18px;
                                              color: white;
                                            "
                                          >
                                            Rs
                                          </span>
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 24px;
                                              color: white;
                                            "
                                          >
                                            {{ item.bidPrice }}
                                          </span>
                                          <v-icon color="white"
                                            >mdi-arrow-up-right-bold</v-icon
                                          >
                                        </v-flex>
                                        <v-flex text-center xs12>
                                          <span
                                            class="mainfont"
                                            style="
                                              font-size: 18px;
                                              color: white;
                                            "
                                          >
                                            Current Price
                                          </span>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-carousel-item>
                    </v-carousel>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex v-else pt-3 xs4 text-center>No Live Auctions !</v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 pa-4 text-center>
            <span
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: bold"
            >
              Pending Approvals
            </span>
          </v-flex>
          <v-flex xs11>
            <v-layout wrap justify-center fill-height>
              <v-flex pr-2 lg4 xs8>
                <v-card
                  class="bg7 pa-8"
                  style="border-radius: 15px"
                  @click="goToActiveUsersPage"
                 
                >
                  <v-layout wrap justify-center>
                    <v-flex xs2>
                      <v-img src="../../assets/Images/icon1.1png.png"></v-img>
                    </v-flex>
                    <v-flex xs5 pl-3 text-center align-self-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: white"
                      >
                        Users
                      </span>
                    </v-flex>
                    <v-flex xs4 text-center align-self-center>
                      <span
                        class="mainfont"
                        style="font-size: 26px; color: white"
                      >
                        {{list.pendingUsers}}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                :class="{
                  'pt-2': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
                }"
                lg4
                xs8
              >
                <v-card
                  style="border-radius: 15px"
                  class="bg7 pa-8"
                  @click="
                  goToLotsPage
                   
                  "
                >
                  <v-layout wrap justify-center>
                    <v-flex xs2>
                      <v-img src="../../assets/Images/icon2.1.png"></v-img>
                    </v-flex>
                    <v-flex xs5 pl-3 text-center align-self-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: white"
                      >
                        Lots
                      </span>
                    </v-flex>
                    <v-flex xs4 text-center align-self-center>
                      <span
                        class="mainfont"
                        style="font-size: 26px; color: white"
                      >
                        {{ list.pendingLots }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
              style="cursor: pointer"

              @click="
                    $router.push({
                      path: '/OrdersPage' },)
                  "
                :class="{
                  'pt-2': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
                }"
                lg4
                pl-2
                xs8
              >
                <v-card class="bg7 pa-8" style="border-radius: 15px">
                  <v-layout wrap justify-center>
                    <v-flex xs2>
                      <v-img src="../../assets/Images/icon3.1.png"></v-img>
                    </v-flex>
                    <v-flex xs5 pl-2 text-center align-self-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: white"
                      >
                        Orders
                      </span>
                    </v-flex>
                    <v-flex xs4 text-center align-self-center>
                      <span
                        class="mainfont"
                        style="font-size: 26px; color: white"
                      >
                        {{ list.pendingOrders }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 pa-4 text-center>
            <span
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: bold"
            >
              Additional Settings
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs11>
            <v-layout wrap justify-center>
              <v-flex
                xs10
                lg4
                style="cursor: pointer"
                @click="
                   $router.push({
                    path: '/ActiveUsersPage',
                    query: { role: 'Farmer', fromDashboard: true },
                  })
                "
              >
                <v-card style="border-radius: 15px" elevation="0">
                  <v-layout wrap justify-center pa-6>
                    <v-flex xs2>
                      <v-img
                        contain
                        height="30px"
                        src="../../assets/Images/icon11.png"
                      ></v-img>
                    </v-flex>
                    <v-flex xs8 text-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                      >
                        Farmer
                      </span>
                    </v-flex>
                    <v-flex xs2>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                        >{{ list.farmers }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                style="cursor: pointer"
                @click="
                  $router.push({
                    path: '/ActiveUsersPage',
                    query: { role: 'Trader',fromDashboard: true  },
                  })
                "
                :class="{
                  'pt-2': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
                }"
                xs10
                lg4
                pl-2
              >
                <v-card style="border-radius: 15px" elevation="0">
                  <v-layout wrap justify-center pa-6>
                    <v-flex xs2>
                      <v-img
                        height="30px"
                        contain
                        src="../../assets/Images/icon12.png"
                      ></v-img>
                    </v-flex>
                    <v-flex xs8 text-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                      >
                        Trader
                      </span>
                    </v-flex>
                    <v-flex xs2>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                        >{{ list.traders }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs10
                lg4
                pl-2
                style="cursor: pointer"
                @click="
                  $router.push({
                    path: '/ActiveUsersPage',
                    query: { role: 'Customer' },
                  })
                "
              >
                <v-card style="border-radius: 15px" elevation="0">
                  <v-layout wrap justify-center pa-6>
                    <v-flex xs2>
                      <v-img
                        height="30px"
                        contain
                        src="../../assets/Images/icon13.png"
                      ></v-img>
                    </v-flex>
                    <v-flex xs8 text-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                      >
                        Customer
                      </span>
                    </v-flex>
                    <v-flex xs2>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                        >{{ list.customers }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex
                xs10
                lg4
                pt-3
                @click="$router.push('/LotsPage')"
                style="cursor: pointer"
              >
                <v-card style="border-radius: 15px" elevation="0">
                  <v-layout wrap justify-center pa-6>
                    <v-flex xs2>
                      <v-img
                        height="30px"
                        contain
                        src="../../assets/Images/icon14.png"
                      ></v-img>
                    </v-flex>
                    <v-flex xs8 text-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                      >
                        Lots
                      </span>
                    </v-flex>
                    <v-flex xs2>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                        >{{ list.lots }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                @click="$router.push('/categoryPage')"
                style="cursor: pointer"
                :class="{
                  'pt-2': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
                }"
                xs10
                lg4
                pl-2
                pt-3
              >
                <v-card style="border-radius: 15px" elevation="0">
                  <v-layout wrap justify-center pa-6>
                    <v-flex xs2>
                      <v-img
                        height="30px"
                        contain
                        src="../../assets/Images/icon15.1.png"
                      >
                      </v-img>
                    </v-flex>
                    <v-flex xs8 text-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                      >
                        Category
                      </span>
                    </v-flex>
                    <v-flex xs2>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                        >{{ list.categories }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex
                xs10
                lg4
                pt-3
                pl-2
                style="cursor: pointer"
                @click="$router.push('/ProductsPage')"
              >
                <v-card style="border-radius: 15px" elevation="0">
                  <v-layout wrap justify-center pa-6>
                    <v-flex xs2>
                      <v-img
                        height="30px"
                        contain
                        src="../../assets/Images/icon16.1png.png"
                      ></v-img>
                    </v-flex>
                    <v-flex xs8 text-center>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                      >
                        Store
                      </span>
                    </v-flex>
                    <v-flex xs2>
                      <span
                        class="mainfont"
                        style="font-size: 18px; color: black"
                        >{{ list.products }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

  <script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      name: null,
      userProfileTabs: null,
      subtab: null,
      phone: null,
      price: null,
      category: null,
      description: null,
      productImage: null,
      productType: null,
      productCode: null,
      liveAuctions: [],
      appLoading: false,
      page: 1,
      pages: 0,
      dialog1: false,
      preview: null,
      tab: null,
      items: ["Dashboard", "Auctions"],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      formData: new FormData(),
      id: this.$route.query.id,
      productname: null,
      productarray: [],
      list: {},
      currentpage: 1,
      limit: 10,
      msg: null,
      showSnackBar: false,
      curid: [],
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    goToActiveUsersPage() {
      this.$router.push({
        path: '/ActiveUsersPage',
        query: { status: 'Pending', fromDashboard: true },
      });
    },

    goToLotsPage() {
      this.$router.push({
        path: '/LotsPage',
        query: { status: 'Pending', fromDashboard2: true },
      });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/admin/dashboard",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.liveAuctions = this.list.liveAuctions;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.analyticsKey {
  font-family: poppinsregular;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 1px;
}
.analyticsValue {
  font-family: poppinsmedium;
  font-size: 25px;
  color: #ffffff;
}
.itemArrow {
  font-family: poppinsregular;
  font-size: 12px;
  color: #ffffff;
}
.bg1 {
  background-image: linear-gradient(to right, #00f2fe 0%, #4facfe 100%);
}
.bg2 {
  background-image: linear-gradient(to right, #f093fb 0%, #f5576c 100%);
}
.bg3 {
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
}
.bg4 {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}
.bg5 {
  background-image: linear-gradient(to right, #fe7b00 0%, #e9e390 100%);
}
.bg6 {
  background-image: linear-gradient(to right, #f093fb 0%, #b445e7 100%);
}
.bg7 {
  background-image: linear-gradient(to right, #13736f 0%, #13736f 100%);
}
.v-carousel__controls .v-carousel__controls__item {
  width: 3px;
  height: 3px;
}
.bg8 {
  border-radius: 10px;
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cardbg {
  background-image: linear-gradient(269.6deg, #29807c -31.66%, #29807c);
}
</style>